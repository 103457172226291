import React from "react";
import { useEngToolbossData } from "lang/en/toolboss";
import Toolboss from "sections/Toolboss/Toolboss";

const ToolbossPage = () => {
  const data = useEngToolbossData();
  return <Toolboss data={data} />;
};

export default ToolbossPage;
