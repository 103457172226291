import { useToolbossPhotos } from "shared/Hooks/toolboss/useToolbossPhotos";

export const useEngToolbossData = () => {
  const photos = useToolbossPhotos();

  return {
    metaInfo: {
      title: "Toolboss",
      ogDescription:
        "Missing a crucial tool at a given time and place can lead to eventual closures of an entire production line, further resulting in massive factory losses. This is why tool distribution is critical for the operations of every factory. ToolBOSS vending machines are not only a guarantee of accessibility to the equipment you need, but also orderly storage, shortened product processing, and more accurate supervision of the condition of your tool inventory.",
      ogImage: "/menu-foto-toolboss.png",
    },
    hero: {
      title: "ToolBOSS Vending machines",
      subtitle:
        "Missing a crucial tool at a given time and place can lead to eventual closures of an entire production line, further resulting in massive factory losses. This is why tool distribution is critical for the operations of every factory. ToolBOSS vending machines are not only a guarantee of accessibility to the equipment you need, but also orderly storage, shortened product processing, and more accurate supervision of the condition of your tool inventory.",
    },
    mainCatalogue: {
      title: "Cost-effective and convenient",
      subtitle:
        "The variety of size and shape of ToolBOSS machines are more than just secure storage. The main goal is better efficiency and profits for you.  After all, they also significantly reduce your admin expenses and spending on replacement tools, as well as speed up the product release date.  On top of that, supervision is simple and convenient, thanks to the seamless integration with ToolBOSS, CAM and NOVO systems.",
      links: [
        {
          url: "/catalogs/toolboss/toolboss.pdf",
          text: "ToolBoss catalog",
        },
      ],
      bigImg: true,
      src: photos.img1,
    },
  };
};
